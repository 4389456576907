import { graphql } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container } from "react-bootstrap"
import BreadcrumbModule from "../components/BreadcrumbModule/BreadcrumbModule"
import ContentComponent from "../components/ContentComponent/ContentComponent"
import LandingStaticCard from "../components/LandingStaticCard/LandingStaticCard"
import OfficeListing from "../components/OfficeListing/OfficeListing"
import SocialShareComponent from "../components/SocialShareComponent/SocialShareComponent"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import { formTracking } from "../components/Common/utils"
import qs from "qs"
import logoBlack from "../images/logo-white.svg"
import { removeDataAttributes } from "../components/Common/utils"

const ContactTemplate = ({ data, pageContext }) => {
  // const PageData = data?.strapiPage

  const [PageData, setPageData] = useState(data?.strapiPage)
  const [loading,setLoading] = useState(true)
  const [isPreviewEnabled, setIsPreviewEnabled]=useState(false)

  const [enablePreview, setEnablePreview] = useState(pageContext?.strapi_preview == "true" ? true : false)


  const officeData = data?.allStrapiOffice?.edges
  const [shareIcons, setShareIcons] = useState(false)

  let popUpData=""

  if(PageData?.add_page_modules?.length>0){
     const results=PageData?.add_page_modules?.filter(item=>item.__typename==="STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT")
     if(results?.length>0){
      popUpData=results[0]
     }
  }


  const openShareicons = () => {
    setShareIcons(true)
    if (shareIcons === true) {
      setShareIcons(false)
    }
    formTracking({
      event_tracking: "share-property",
      form_name: "Share Property ",
    })
  }

  const pageurl = typeof window !== "undefined" ? window.location.href : ""

  var ldJson = {
    "@context": "http://www.schema.org",
"@type": "RealEstateAgent",
name: "Real Estate in Dubai",
url: pageurl,
logo: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/logo_white_schema_9fd27d4e15.png",
image: "https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/haus_homepage_contact_headoffice_e092721ac2_9585806d12.png",
"description": PageData?.seo?.metaTitle,
"address": {
"@type": "PostalAddress",
"addressCountry": "Dubai"
},
"geo": {
"@type": "GeoCoordinates",
"latitude": "25.1262428",
"longitude": "55.1428478"
},
// hasMap : "[MapURL!]",
// sameAs : "[Map URL!]",
openingHours: "[Mon - Fri:[9.00AM - 6.00PM], Sat: [9.00AM - 6.00PM], Sun: Closed",
email: "info@hausandhaus.com",
telephone: "+971 4 302 5800",
contactPoint: 	{
"@type": "ContactPoint",
contactType: "Sales",
telephone: "+971 4 302 5800"
},
aggregateRating: {
"@type": "AggregateRating",
ratingValue: "4.7",
reviewCount: "700+"
}
}

const query = qs.stringify(
  {
    populate: {
      ggfx_results:"*",
      choose_menu: "*",
      select_popular_search:"*",
      seo:"*",
      add_page_modules: {
        on: {
          "components.title-desc":{
            populate:"*"
          },
          "page-modules.global-module":{
            populate:{
              select_module:"*"
            }
          },
          "page-modules.static-card-items":{
            populate:{
              cards:{
                populate:{
                  cta:{
                    populate:{
                      link: {
                        populate:{
                          parent:"*"
                        }
                      },
                      image:"*"
                    }
                  }
                }
           }

        }
      }
        },
      },
    },
  },
  {
    encodeValuesOnly: true, // prettify URL
  }
);

useEffect(()=>{
  const urlParams = new URLSearchParams(window.location.search);
  const isPreview = urlParams.get('debug') === 'preview';
  const apiUrl=`${process.env.GATSBY_STRAPI_SRC}/api/pages/${urlParams.get('strapi_id')}?${query}`

  // const apiUrl=process.env.GATSBY_STRAPI_SRC+'/api/events/'+urlParams.get('strapi_id')+'?populate[0]=modules&populate[1]=banner.cta_1_link&populate[2]=banner.cta_2_link&populate[3]=modules.icon_content&populate[4]=modules.icon_content.icon_module.image&populate[5]=form_content&populate[6]=ggfx_results&populate[12]=banner.image'
  const bearerToken = process.env.GATSBY_STRAPI_API_TOKEN;

  if(isPreview){
  setIsPreviewEnabled(true)
  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });
      const getData = await response.json();
      const previewData=removeDataAttributes(getData)
      setLoading(false)
      setEnablePreview(false)

      let myPrevData;
      if(PageData){
        myPrevData = PageData
      } else {
        myPrevData = {};
        myPrevData.add_page_modules=[]
        myPrevData.id = urlParams.get('strapi_id')
      }

      if(previewData){
        
        myPrevData.imagetransforms = previewData?.imagetransforms
        myPrevData.title = previewData?.title
        myPrevData.layout = previewData?.layout
        myPrevData.select_popular_search = previewData.select_popular_search
        myPrevData.choose_menu = previewData?.choose_menu

        myPrevData.ggfx_results = previewData?.ggfx_results
        myPrevData.add_page_modules=previewData?.add_page_modules
        setPageData(PageData => ({ ...PageData, myPrevData }));
        sessionStorage.setItem("previewMeta",JSON.stringify(previewData?.seo))
      }
      
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  };
  fetchData();
  }
  else{
    setLoading(false)
    setIsPreviewEnabled(false)
    sessionStorage.removeItem("previewMeta")
  }

},[])

if(loading){
  return (
    <section className={"loader-wrapper"}>
      <div id="loader-wrapper">
        <div id="loader" class="new-loader">
          <img
            className="logo-white loader-logo"
            src={logoBlack}
            alt="logo"
          />
        </div>
      </div>
    </section>
  )
}

if(enablePreview){
  return null
}

let queryValue = ""
if(typeof window !== "undefined"){
  const getQuery = new URLSearchParams(window.location.search);
  if(getQuery.get("open-form")){
    queryValue=getQuery.get("open-form")
  }
}

  return (
    <Layout popUpData={popUpData} dark={true} popularSearch={PageData?.select_popular_search?.title}>
      <Helmet>
        <script type="application/ld+json">
            {JSON.stringify(ldJson, null, 2)}
        </script>
      </Helmet>
      <div className="layout-padding-top"></div>
      
      <Container>
        <div className="breadcum-share-wrapper">
          <BreadcrumbModule
            choose_menu={PageData?.choose_menu}
            pagename={PageData?.title}
          />
          <div className="share-news-details" onClick={() => openShareicons()}>
            <i className="icon grey-share" />
            <span>Share</span>
            {shareIcons && (
              <SocialShareComponent
                openShareicons={openShareicons}
                shareurl={pageurl}
              />
            )}
          </div>
        </div>
      </Container>
      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {(item?.__typename === "STRAPI__COMPONENT_COMPONENTS_TITLE_DESC" ||
              item?.__component === "components.title-desc")
            && (
              <ContentComponent isPreviewEnabled={isPreviewEnabled} data={item} />
            )}

            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS" ||
              item?.__component === "page-modules.static-card-items")
              && (
              <LandingStaticCard  isPreviewEnabled={isPreviewEnabled} data={item} />
            )}
            {(item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" ||
              item?.__component === "page-modules.global-module")
              &&
              item?.select_module === "offices" && (
                <OfficeListing
                  officeData={officeData}
                  strapi_id={PageData?.strapi_id}
                  queryValue={queryValue}
                />
              )}
          </div>
        )
      })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage

  //preview seo dynamic updates

  let isPreview=""
  if(typeof window!=="undefined"){
  const urlParams = new URLSearchParams(window.location.search);
   isPreview = urlParams.get('debug') === 'preview';
  }

  const metaTitle = PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title

  const [preMetaTitle, setPreMetaTitle] = useState(metaTitle)


  useEffect(() => {
    const timer = setTimeout(() => {
      if (typeof window !== "undefined" && isPreview) {
        try {
          const sessionMetaData = sessionStorage.getItem("previewMeta");
          if (sessionMetaData) {
            const parsedData = JSON.parse(sessionMetaData);
          if(parsedData?.metaTitle){
            setPreMetaTitle(parsedData?.metaTitle)
          }
        }
      } catch (error) {
        console.error("Error parsing session storage data:", error);
      }
    }
  }, 4000);

  // Clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [isPreview]);

  useEffect(() => {
    if(isPreview){
    document.title =  `${preMetaTitle} haus & haus`;
    }
  }, [preMetaTitle]);
  //

  return (
      <SEO title={(isPreview && preMetaTitle) ? preMetaTitle : metaTitle ? metaTitle : PageData?.title} 
      description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} 
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i/file_7035ecdafe.jpg?updated_at=2024-07-08T11:28:34.283Z"

      />
  )
}

export default ContactTemplate

export const query = graphql`
  query ($page_id: String) {
    allStrapiOffice(filter: { publish: { eq: true } },sort: { fields: rank, order: ASC }) {
      edges {
        node {
          title
          phone
          email
          tile_image {
            url
          }
          imagetransforms {
            image_Transforms
          }
          add_location {
            custom_link
            title
          }
        }
      }
    }
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment

      add_page_modules {
        ... on STRAPI__COMPONENT_COMPONENTS_TITLE_DESC {
          __typename
          id
          title
          description {
            data {
              description
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          id
          select_module
          __typename
        }


        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_STATIC_CARD_ITEMS {
          __typename
          id
          cards {
            title
            content {
              data {
                content
              }
            }
            cta {
              link {
                slug
                strapi_parent {
                  slug
                }
              }
              link_type
              custom_link
              title
            }
          }
        }
      }
    }
  }
`
